import { Button, Form } from "antd";
import styled from "styled-components";

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormBlock = styled(Form)`
  width: 300px;
`;

export const ButtonBlock = styled(Button)`
  width: 100%;
`;
