import { configureStore } from "@reduxjs/toolkit";
import api from "./api/api";
import apiV2 from "./api/v2";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [apiV2.reducerPath]: apiV2.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, apiV2.middleware),
});
