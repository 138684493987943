import apiV2 from ".";

export const userLinksApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    createUserLink: builder.mutation({
      query: ({ domainId, eventId, userIdB }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/links`,
        method: "POST",
        body: { userIdB },
      }),
      invalidatesTags: ["USER_LINKS", "USERS"],
    }),
  }),
});

export const { useCreateUserLinkMutation } = userLinksApi;
