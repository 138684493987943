import { Button } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

function BackButton() {
  const navigate = useNavigate();
  const { context } = useParams();

  return (
    <Button type="primary" onClick={() => navigate(`/${context}`)}>
      Retour
    </Button>
  );
}

export default BackButton;
