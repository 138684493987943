import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import React from "react";

export default function BooleanIcon({ value }) {
  return value ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <CheckCircleOutlined style={{ color: "green" }} />
    </div>
  ) : (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <CloseCircleOutlined style={{ color: "red" }} />
    </div>
  );
}
