import { Button, Form } from "antd";
import styled from "styled-components";

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftBlock = styled.div`
  width: 48%;
`;

export const RightBlock = styled.div`
  width: 48%;
`;

export const FormBlock = styled(Form)``;

export const ButtonBlock = styled(Button)`
  width: 100%;
`;
