/* eslint-disable no-underscore-dangle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { Alert, Button } from "antd";
import React, { useMemo, useRef, useState } from "react";
import { read, utils } from "xlsx";
import { useNavigate } from "react-router-dom";
import useMyContext from "../../hooks/useMyContext";
import { useImportUsersMutation } from "../../api/v2/import";
import BackButton from "../../components/BackButton/BackButton";
import { Center, LeftBlock, RightBlock } from "./UsersInvitedSoloScreen.styles";
import useSettings from "../../hooks/useSettings";
import { useGetMeQuery, useGetUserEventQuery } from "../../api/user.api";
import { useGetMyLinksQuery } from "../../api/v2/users";

const transformedObject = (inputObject) =>
  Object.entries(inputObject).reduce((prev, [key, value]) => {
    if (!key.includes("(") || !key.includes(")") || !key.includes(".")) {
      return prev;
    }

    const [model, property, extended, values] = key.split("(")[1].split(")")[0].split(".");

    if (extended === "values") {
      return {
        ...prev,
        [model]: {
          ...(prev[model] || {}),
          [property]: {
            ...(prev[model]?.[property] || {}),
            [values]: value.split(",").reduce((p, curr) => ({ ...p, [curr]: true }), {}),
          },
        },
      };
    }

    if (extended) {
      return {
        ...prev,
        [model]: {
          ...(prev[model] || {}),
          [property]: {
            ...(prev[model]?.[property] || {}),
            [extended]: value,
          },
        },
      };
    }

    if (property) {
      return {
        ...prev,
        [model]: {
          ...(prev[model] || {}),
          [property]: value,
        },
      };
    }

    return prev;
  }, {});

export default function ImportUsersV2() {
  const [data, setData] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const myContext = useMyContext();
  const settings = useSettings();
  const ref = useRef();
  const navigate = useNavigate();

  const [importUsers, { isLoading }] = useImportUsersMutation();
  const { data: me } = useGetMeQuery({ ...myContext });
  const { data: eventUser } = useGetUserEventQuery({ ...myContext, userId: me?.id });
  const { data: links } = useGetMyLinksQuery({ ...myContext, userId: me?.id });

  const quota = useMemo(
    () =>
      (eventUser?.UserEvent?.invitationsQuota || 0) - (links?.length || 0) < 0
        ? 0
        : (eventUser?.UserEvent?.invitationsQuota || 0) - (links?.length || 0),
    [eventUser, links]
  );
  const quotaReached = useMemo(
    () => (eventUser?.UserEvent?.invitationsQuota && data && links ? data.length > quota : false),
    [eventUser, data, links, quota]
  );

  const handleFileUpload = (event) => {
    setIsSuccess(false);
    const file = event.target.files[0];

    const reader = new FileReader();

    reader.onload = (e) => {
      const _data = new Uint8Array(e.target.result);
      const workbook = read(_data, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const excelData = utils.sheet_to_json(workbook.Sheets[sheetName], { defval: "" });
      setData(excelData.map((m) => transformedObject(m)));
    };

    reader.readAsArrayBuffer(file);
  };

  const reset = () => {
    setData([]);
  };

  return (
    <div>
      <BackButton />
      <Center>
        <LeftBlock>
          <div dangerouslySetInnerHTML={{ __html: settings?.FO_INVITATIONS_INVITE_MULTIPLE_HTML?.html }} />
        </LeftBlock>
        <RightBlock>
          <div style={{ display: "flex", gap: 16, flexDirection: "column" }}>
            {data.length === 0 && (
              <div>
                <input ref={ref} type="file" accept=".xlsx, .xls" onChange={handleFileUpload} value={undefined} hidden />
                <Button style={{ width: "100%" }} type="primary" onClick={() => ref.current?.click()}>
                  {`Choisir un fichier excel d'utilisateurs`}
                </Button>
              </div>
            )}

            {quotaReached && (
              <Alert
                description={`Vous ne pouvez importer plus que ${quota} utilisateur(s), alors que ${data.length} utilisateur(s) sont présents dans votre fichier.`}
                type="error"
                style={{ display: "flex", alignItems: "center" }}
                action={
                  <div style={{ display: "flex", gap: 8, flexWrap: "wrap", alignItems: "center" }}>
                    <Button
                      type="primary"
                      onClick={async () => {
                        reset();
                      }}
                    >
                      {`D'accord`}
                    </Button>
                  </div>
                }
              />
            )}

            {!quotaReached && data.length > 0 && (
              <Alert
                description={`Voulez-vous importer ${data.length} users ?`}
                type="info"
                action={
                  <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
                    <Button
                      type="primary"
                      onClick={async () => {
                        //   const c = [];
                        // for (let i = 0; i < 100; i++) {
                        //   c.push({
                        //     user: {
                        //       firstName: `firstName ${i}`,
                        //       lastName: `lastName ${i}`
                        //     }
                        //   })
                        // }

                        try {
                          const dataFinal = data?.map((m) => ({
                            ...m,
                            userEvent: { ...(m.userEvent || {}), invitedBy: me.id, invited: true, invitedAt: new Date() },
                          }));
                          await importUsers({ ...myContext, data: dataFinal }).unwrap();
                          setIsSuccess(true);
                          reset();
                          navigate("../", { state: { usersTotal: dataFinal.length } });
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      loading={isLoading}
                    >
                      Oui
                    </Button>
                    <Button onClick={() => reset()} loading={isLoading} danger ghost>
                      Non
                    </Button>
                  </div>
                }
              />
            )}

            {isSuccess && <Alert showIcon message="Import terminé" type="success" />}

            {data && window.location.hostname === "localhost" && (
              <div>
                <h2>Contenu du fichier Excel</h2>
                <pre>{JSON.stringify(data, null, 2)}</pre>
              </div>
            )}
          </div>
        </RightBlock>
      </Center>
    </div>
  );
}
