import apiV2 from ".";

export const emailsApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    sendEmail: builder.mutation({
      query: ({ domainId, eventId, userId, templateId, email, variables }) => ({
        url: `/domains/${domainId}/emails/send`,
        method: "POST",
        body: { templateId, userId, eventId, email, variables },
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["EMAILS"],
    }),
  }),
});

export const { useSendEmailMutation } = emailsApi;
