/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import { Alert, Button, Checkbox, Divider, Form, Input, InputNumber, Modal, Radio, Select } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useGetDataQuery, useGetFormsQuery, useUpdateDataMutation } from "../api/v2/forms";
import useTranslations from "../hooks/useTranslations";
import useMyContext from "../hooks/useMyContext";

const capitalizeWords = (str) => str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());

const toUpperCase = (str) => str.toUpperCase();

function Component(props) {
  const { model, value, onChange } = props;

  const { eventId } = useMyContext();

  const valuesArray = useMemo(() => {
    if (value) {
      return Object.keys(value);
    }
    return [];
  }, [value]);

  const valueString = useMemo(() => {
    if (value) {
      const arr = Object.keys(value);
      return arr.length ? arr[0] : null;
    }
    return null;
  }, [value]);

  const onChangeValuesArray = useCallback(
    (e) => {
      const v = e?.target?.value || e;
      onChange(v.reduce((prev, curr) => ({ ...prev, [curr]: true }), {}));
    },
    [onChange]
  );

  const onChangeValueString = useCallback(
    (e) => {
      const v = e?.target?.value || e;
      onChange({ [v]: true });
    },
    [onChange]
  );

  const disabled = useMemo(() => (model.model === "UE" && !eventId) || model.isDisabled, [model, eventId]);

  if (model.component === "textarea") {
    return <Input.TextArea {...props} rows={3} disabled={disabled} />;
  }

  if (model.component === "input-number") {
    return <InputNumber {...props} disabled={disabled} />;
  }

  if (model.component === "input") {
    return <Input {...props} disabled={disabled} />;
  }

  if (model.component === "select") {
    return <Select {...props} value={valueString} onChange={onChangeValueString} options={model.values} disabled={disabled} />;
  }

  if (model.component === "select-multiple") {
    return (
      <Select mode="multiple" {...props} value={valuesArray} onChange={onChangeValuesArray} options={model.values} disabled={disabled} />
    );
  }

  if (model.component === "radio") {
    return <Radio.Group {...props} value={valueString} onChange={onChangeValueString} options={model.values} disabled={disabled} />;
  }

  if (model.component === "checkbox") {
    return <Checkbox.Group {...props} value={valuesArray} onChange={onChangeValuesArray} options={model.values} disabled={disabled} />;
  }

  return <div />;
}

function FormItem({ model }) {
  if (model.component === "comment") {
    return <Divider>{model.label}</Divider>;
  }

  return (
    <Form.Item
      label={model.label}
      name={model.isExtended ? [model.model, "extended", model.key] : [model.model, model.key]}
      rules={[{ required: model.isRequired, message: "Ce champ est obligatoire" }]}
      normalize={(value) => {
        if (model.format === "capitalize-words") {
          return capitalizeWords(value);
        }
        if (model.format === "uppercase") {
          return toUpperCase(value);
        }
        return value;
      }}
    >
      <Component model={model} />
    </Form.Item>
  );
}

const defaultUserForm = {
  name: "User",
  id: 184,
  type: "U",
  layout: "horizontal",
  models: [
    {
      value: "Prénom",
      label: "Prénom",
      key: "firstName",
      isExtended: 0,
      model: "U",
      component: "input",
      isRequired: 1,
      modelId: 5317,
      order: 0,
      values: [],
    },
    {
      value: "Nom",
      label: "Nom",
      key: "lastName",
      isExtended: 0,
      model: "U",
      component: "input",
      isRequired: 1,
      modelId: 5320,
      order: 1,
      values: [],
    },
    {
      value: "Email",
      label: "Email",
      key: "email",
      isExtended: 0,
      model: "U",
      component: "input",
      isRequired: 0,
      modelId: 5321,
      order: 2,
      values: [],
    },
    {
      value: "Mot de passe",
      label: "Mot de passe",
      key: "password",
      isExtended: 0,
      model: "U",
      component: "input",
      isRequired: 0,
      modelId: 5321,
      order: 3,
      values: [],
    },
  ],
};

const defaultCompanyForm = {
  type: "C",
  layout: "horizontal",
  models: [
    {
      value: "Nom",
      label: "Nom",
      key: "name",
      isExtended: 0,
      model: "C",
      component: "input",
      isRequired: 1,
      modelId: 5317,
      order: 0,
      values: [],
    },
  ],
};

export default function FormBuilder({ children, userId, companyId, title, withModal, onFinish, formId }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const myContext = useMyContext();
  const [form] = Form.useForm();
  const { t } = useTranslations();

  const { data: forms2 } = useGetFormsQuery({ ...myContext });
  const { data } = useGetDataQuery({ ...myContext, userId, companyId });
  const [update, { error }] = useUpdateDataMutation();

  const forms = useMemo(() => forms2?.filter((f) => (userId ? f.type === "U" : f.type === "C")), [forms2, userId, companyId]);

  const currentForm = useMemo(() => {
    if (forms) {
      const find = forms?.find((f) => f.id === formId);
      if (find) {
        return find;
      }
      if (userId) {
        return defaultUserForm;
      }
      return defaultCompanyForm;
    }
    return null;
  }, [forms, formId]);

  useEffect(() => {
    if (data && open && currentForm) {
      // form.resetFields();
      // form.setFieldsValue(data);
    }
  }, [data, open, currentForm]);

  const Builder = useCallback(
    () => (
      <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        {currentForm && (
          <Form
            form={form}
            layout={currentForm.layout}
            onFinish={async (body) => {
              try {
                setLoading(true);
                const result = await update({ ...myContext, userId, companyId, body: { ...body, force: true } }).unwrap();
                await onFinish(result);
                setOpen(false);
              } catch (err) {
                console.log(err);
              } finally {
                setLoading(false);
              }
            }}
          >
            {currentForm.models.map((m) => (
              <FormItem model={m} />
            ))}
            {error && (
              <Form.Item>
                <Alert type="error" message={t(error?.data?.message)} showIcon />
              </Form.Item>
            )}
            <div
              style={{
                position: "sticky",
                display: "flex",
                gap: 8,
                bottom: 0,
                backgroundColor: "white",
                paddingTop: 8,
                zIndex: 9999,
              }}
            >
              <Button onClick={() => setOpen(false)}>Annuler</Button>
              <Button type="primary" htmlType="submit" style={{ flexGrow: 1 }} loading={loading}>
                Enregistrer
              </Button>
            </div>
          </Form>
        )}
      </div>
    ),
    [forms, formId, currentForm, myContext, userId, companyId, error, loading, form, onFinish]
  );

  if (!withModal) {
    return <Builder />;
  }

  return (
    <>
      {React.cloneElement(children, {
        onClick: () => {
          setOpen(true);
        },
      })}
      <Modal
        title={title || " "}
        visible={open}
        footer={null}
        onCancel={() => setOpen(false)}
        bodyStyle={{
          maxHeight: "calc(100vh - 300px)",
          overflowY: "auto",
        }}
      >
        <Builder />
      </Modal>
    </>
  );
}
