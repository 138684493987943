/* eslint-disable react/jsx-no-useless-fragment */
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Input, Space, Table, Tag } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetEmailConfigurationQuery } from "../../api/email.api";
import { useGetUsersInvitedByMeQuery } from "../../api/eventUsers.api";
import { useGetMeQuery } from "../../api/user.api";
import { useGetMyLinksQuery } from "../../api/v2/users";
import BooleanIcon from "../../components/BooleanIcon";
import useEnv from "../../hooks/useEnv";
import useMyContext from "../../hooks/useMyContext";
import useSettings from "../../hooks/useSettings";
import useDates from "../../hooks/useDates";
import FormBuilder from "../../components/FormBuilder";
import { useCreateUserLinkMutation } from "../../api/v2/userLinks";
import { useUpdateParticipationMutation } from "../../api/v2/participations";
import SendInvitation from "./SendInvitation";

function UsersInvitedListScreen() {
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const myContext = useMyContext();
  const settings = useSettings();
  const { state } = useLocation();
  const { context } = useParams();
  const env = useEnv();
  const { dayHourMinute } = useDates();

  const { data: me } = useGetMeQuery({ ...myContext });
  const { data: users, refetch } = useGetUsersInvitedByMeQuery({ ...myContext });
  const { data, refetch: refetch2, isFetching } = useGetMyLinksQuery({ ...myContext, userId: me?.id });
  const { data: config } = useGetEmailConfigurationQuery({ ...myContext });
  const [createUserLink] = useCreateUserLinkMutation();
  const [updateParticipation] = useUpdateParticipationMutation();

  const getUrl = useCallback(
    (token) => {
      let baseUrl = "https://meeting.gayakoa.com";
      if (env === "local") {
        baseUrl = "http://localhost:4001";
      } else if (env === "dev") {
        baseUrl = "https://d1cwsl2gmmbvf6.cloudfront.net";
      }
      return `${baseUrl}/${context}?token=${token}`;
    },
    [env, context]
  );

  useEffect(() => {
    if (state?.usersTotal || state?.count) {
      refetch();
      refetch2();
    }
  }, [state, refetch, refetch2]);

  const linksFiltered = useMemo(() => {
    if (!search) return data?.users;

    const lowerSearch = search.toLowerCase();

    return data?.users.filter(
      ({ firstName, lastName, email }) =>
        (firstName || "").toLowerCase().includes(lowerSearch) ||
        (lastName || "").toLowerCase().includes(lowerSearch) ||
        (email || "").toLowerCase().includes(lowerSearch)
    );
  }, [data, search]);

  const expandedRowRender = (record) => (
    <Table
      size="small"
      rowKey="id"
      columns={[
        { title: "Prénom", dataIndex: "firstName" },
        { title: "Nom", dataIndex: "lastName" },
      ]}
      dataSource={record.companions}
      pagination={false}
    />
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <div dangerouslySetInnerHTML={{ __html: settings?.FO_INVITATIONS_LIST_USERS_HTML?.html }} />
      {state?.count && (
        <Alert
          type="success"
          showIcon
          message={`Vous avez importé ${state.total || 0} contacts sur ${
            state.count
          } contacts. Si vous constatez une différence, elle est dû à des adresses emails erronées.`}
        />
      )}
      {state?.usersTotal && <Alert type="success" showIcon message={`Vous avez importé ${state.usersTotal || 0} contacts.`} />}
      <Space>
        {!settings?.FO_INVIT_HIDE_CONFIG_EMAIL && (
          <Button type="primary" onClick={() => navigate("../email-configuration")}>
            Configuration email
          </Button>
        )}
        {settings?.FO_INVIT_FORM_ID ? (
          <FormBuilder
            userId={-1}
            title="Inviter un contact"
            formId={settings?.FO_INVIT_FORM_ID}
            onFinish={async (v) => {
              await Promise.all([
                createUserLink({ ...myContext, userIdB: v.id }).unwrap(),
                updateParticipation({
                  ...myContext,
                  userId: v.id,
                  participation: { invitedBy: me.id, invited: true },
                }).unwrap(),
              ]);
            }}
            withModal
          >
            <Button type="primary">Inviter un contact</Button>
          </FormBuilder>
        ) : (
          <Button type="primary" onClick={() => navigate("../invite-solo")} disabled={!settings?.FO_INVIT_HIDE_CONFIG_EMAIL && !config}>
            Inviter un contact
          </Button>
        )}

        {!settings?.FO_INVIT_IMPORT_USERS_HIDE && (
          <Button type="primary" onClick={() => navigate("../invite-multiple")} disabled={!settings?.FO_INVIT_HIDE_CONFIG_EMAIL && !config}>
            Inviter des contacts (excel)
          </Button>
        )}

        <Button>{`Vous avez invité ${data?.users.length || 0} personne(s). ${
          data?.users?.filter((f) => f.isRegistered).length
        } inscription(s) directe(s) et ${data?.companionsTotal || 0} accompagnant(s). Au total ${
          (data?.companionsTotal || 0) + (data?.users?.filter((f) => f.isRegistered).length || 0)
        } inscrits`}</Button>
        {/* <Tag style={{ alignSelf: "flex-end" }} color="processing" /> */}
      </Space>
      {!settings?.FO_INVIT_SHOW_USER_LINKS ? (
        <>
          {users?.length > 0 ? (
            <Table
              scroll={{ x: true }}
              rowKey="id"
              columns={[
                { title: "Prénom", dataIndex: ["user", "firstName"], sorter: (a, b) => a.user.firstName.localeCompare(b.user.firstName) },
                { title: "Nom", dataIndex: ["user", "lastName"], sorter: (a, b) => a.user.lastName.localeCompare(b.user.lastName) },
                {
                  title: "Email",
                  dataIndex: ["user", "auth", "email"],
                  sorter: (a, b) => a.user.auth?.email.localeCompare(b.user.auth?.email),
                },
                {
                  title: "Inscrit",
                  dataIndex: "registeredFrom",
                  render: (registered) =>
                    registered ? <CheckCircleOutlined style={{ color: "green" }} /> : <CloseCircleOutlined style={{ color: "red" }} />,
                  sorter: (a, b) => (b.registeredFrom && !a.registeredFrom ? 1 : -1),
                },
              ]}
              dataSource={users}
            />
          ) : (
            <Alert type="info" showIcon message="Vous n’avez pas encore envoyé d’invitation" />
          )}
        </>
      ) : (
        <>
          {data?.users.length > 0 ? (
            <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Input placeholder="Rechercher par l'email, le nom ou le prénom" onChange={(v) => setSearch(v.target.value)} value={search} />
              <Table
                size="small"
                rowKey="id"
                scroll={{ x: true }}
                loading={isFetching}
                dataSource={linksFiltered}
                pagination={{ showSizeChanger: true }}
                expandable={{
                  expandedRowRender,
                  rowExpandable: (record) => record.companions && record.companions.length > 0,
                }}
                columns={[
                  { title: "Prénom", dataIndex: "firstName", sorter: (a, b) => a.firstName?.localeCompare(b.firstName) },
                  { title: "Nom", dataIndex: "lastName", sorter: (a, b) => a.lastName?.localeCompare(b.lastName) },
                  { title: "Email", dataIndex: "email", sorter: (a, b) => a.email?.localeCompare(b.email) },
                  { title: "Mobile", dataIndex: "mobile", sorter: (a, b) => a.mobile?.localeCompare(b.mobile) },
                  {
                    title: "Invitation",
                    dataIndex: "sendAt",
                    render: (v) => (v ? dayHourMinute(v) : "Envoi en attente"),
                  },
                  ...(myContext?.eventId && settings?.FO_INVIT_REPORT_ACTIVE
                    ? [
                        {
                          title: "Participation",
                          render: (v) => {
                            if (v.responded) {
                              if (v.isRegistered) {
                                return "Inscrit";
                              }
                              return "Décliné";
                            }
                            return "En attente";
                          },
                          sorter: (a, b) => b.isRegistered - a.isRegistered,
                          defaultSortOrder: "ascend",
                        },
                        {
                          title: "Présent",
                          dataIndex: "isPresent",
                          render: (v) => <BooleanIcon value={v} />,
                          sorter: (a, b) => b.isPresent - a.isPresent,
                        },
                      ]
                    : []),
                  ...(settings?.FO_INVIT_REPORT_ACTIVE
                    ? [
                        {
                          render: (v) => (
                            <div style={{ display: "flex", gap: 8 }}>
                              <Button type="primary" href={getUrl(v.token)} target="_blank" loading={isFetching} disabled={!v.token}>
                                Inscrire
                              </Button>
                              {settings?.FO_INVIT_SEND_INVITATION_SHOW && settings?.FO_MEETING_EMAIL_SEND_INVITATION && (
                                <SendInvitation userId={v.id} />
                              )}
                            </div>
                          ),
                        },
                      ]
                    : []),
                ]}
              />
            </div>
          ) : (
            <Alert type="info" showIcon message="Vous n’avez pas encore envoyé d’invitation" />
          )}
        </>
      )}
    </div>
  );
}

export default UsersInvitedListScreen;
