import { Button } from "antd";
import React from "react";
import useSettings from "../../hooks/useSettings";
import useMyContext from "../../hooks/useMyContext";
import { useSendEmailMutation } from "../../api/v2/emails";

export default function SendInvitation({ userId }) {
  const settings = useSettings();
  const myContext = useMyContext();

  const [sendEmail, { isSuccess, isLoading }] = useSendEmailMutation();

  return (
    <Button
      onClick={() => {
        sendEmail({
          ...myContext,
          userId,
          templateId: settings.FO_MEETING_EMAIL_SEND_INVITATION,
        }).unwrap();
      }}
      type="primary"
      loading={isLoading}
      disabled={isSuccess}
    >
      Envoyer une invitation
    </Button>
  );
}
