import { Button, Result } from "antd";
import React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useGetContextQuery } from "../../api/api";
import { useGetMeQuery } from "../../api/user.api";
import MyLoading from "../../components/MyLoading/MyLoading";
import useMyContext from "../../hooks/useMyContext";
import { ContextBar, Footer, Header, LayoutContainer, LayoutMaxWidth } from "./LayoutScreen.styles";

function LayoutScreen() {
  const navigate = useNavigate();
  const { context } = useParams();
  const myContext = useMyContext();

  const { data, isError, isFetching } = useGetContextQuery(context);
  const { data: me, isError: meIsError } = useGetMeQuery({ ...myContext });

  if (isFetching) {
    return <MyLoading />;
  }

  if (isError) {
    return <Result status="404" title="404" subTitle="Désolé, ce context n'existe pas." />;
  }

  return (
    <LayoutContainer style={{ backgroundColor: data.settings?.backgroundColor }}>
      <LayoutMaxWidth style={{ backgroundColor: "white" }}>
        <div>
          {me && !meIsError && (
            <ContextBar>
              <div>
                {`Connecté en tant que `}
                <strong>
                  {me.firstName} {me.lastName}
                </strong>
              </div>
              <Button
                type="primary"
                onClick={() => {
                  localStorage.removeItem("token");
                  navigate(0);
                }}
              >
                Se déconnecter
              </Button>
            </ContextBar>
          )}
          {data.settings?.headerImg && (
            <Header
              alt="header"
              src={`https://gayakoa-files-eu-west-1-public.s3.eu-west-1.amazonaws.com/${data.settings.headerImg}`}
              style={{ width: "100%" }}
            />
          )}
          <div style={{ padding: 8 }}>
            <Outlet />
          </div>
        </div>

        {data.settings?.footerImg && (
          <Footer
            alt="footer"
            src={`https://gayakoa-files-eu-west-1-public.s3.eu-west-1.amazonaws.com/${data.settings.footerImg}`}
            style={{ width: "100%", marginTop: "10px" }}
          />
        )}
      </LayoutMaxWidth>
    </LayoutContainer>
  );
}

export default LayoutScreen;
