import queryString from "query-string";
import apiV2 from ".";

export const importApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    importCompanies: builder.mutation({
      query: ({ domainId, companies }) => ({
        url: `/domains/${domainId}/companies/import`,
        method: "POST",
        body: { companies },
      }),
    }),
    importUsers: builder.mutation({
      query: ({ domainId, eventId, data }) => ({
        url: `/domains/${domainId}/users/import?${queryString.stringify({ eventId, createLinks: true })}`,
        method: "POST",
        body: { data },
      }),
    }),
  }),
});

export const { useImportCompaniesMutation, useImportUsersMutation } = importApi;
